<template>
<div>
  <div class="tw-mt-16 tw-mb-10 lg:tw-w-7/12	">
    <h2 class="type">When would you like to schedule this Interview?</h2>
  </div>
  <div class=" lg:tw-w-9/12">
      <v-dialog
          ref="timeDialog"
          v-model="timeModal"
          :return-value.sync="interview.time"
          persistent
          width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
              v-model="interview.time"
              readonly
              solo
              placeholder="Select Time"
              prepend-inner-icon="mdi-clock-time-four-outline"
              v-bind="attrs"
              v-on="on"
              class="text"
              id="time"
          >
          </v-text-field>
        </template>
        <v-time-picker
            v-model="interview.time"
            scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
              text
              color="primary"
              @click="timeModal = false"
          >
            Cancel
          </v-btn>
          <v-btn
              text
              color="primary"
              @click="$refs.timeDialog.save(interview.time)"
          >
            OK
          </v-btn>
        </v-time-picker>
      </v-dialog>
    <v-dialog
        ref="dialog"
        v-model="modal"
        :return-value.sync="interview.date"
        persistent
        width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
            v-model="interview.date"
            readonly
            solo
            placeholder="Select Date"
            prepend-inner-icon="mdi-calendar"
            v-bind="attrs"
            v-on="on"
            class="text tw-mt-5"
            id="date"
        >
          Select
        </v-text-field>
      </template>
      <v-date-picker
          v-model="interview.date"
          scrollable
          :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)">
        <v-spacer></v-spacer>
        <v-btn
            text
            color="primary"
            @click="modal = false"
        >
          Cancel
        </v-btn>
        <v-btn
            text
            color="primary"
            @click="$refs.dialog.save(interview.date)"
        >
          OK
        </v-btn>
      </v-date-picker>
    </v-dialog>
    <div class="tw-flex tw-mt-8 tw-mb-8">
      <BaseButton id="back" outlined button-text="Back" width="85px" @click="$router.go(-1)"/>
      <BaseButton id="next" depressed button-text="Next" class="tw-ml-4" width="85px" @click="setInterviewSchedule"/>
    </div>

  </div>
</div>
</template>

<script>
import BaseButton from "@/components/UIComponents/BaseButton";
import {mapGetters} from 'vuex'
import displaySnackbar from "@/services/utils/snackbar"
export default {
name: "InterviewScheduleDateAndTime",
  components: {BaseButton},
  data(){
    return{
      interview:{
        time:'',
        date:'',
      },
      modal:false,
      timeModal:false,
      minDate: (new Date(new Date().getTime() + (24 * 60 * 60 * 1000))).toISOString().substr(0, 10)

    }
  },
  computed:{
  ...mapGetters("interview",['getInterviewSchedule']),

  },
  methods:{
  validateTimeAndDate(){
    if(this.interview&& this.interview.date && this.interview.time){
      return true
    }
    else{
      displaySnackbar({
        message: 'Please provide interview time and date.',
        success: false
      })
      return false
    }


  },
      setInterviewSchedule() {
        if (this.validateTimeAndDate()) {
          // let job = this.$route.query.jobId
          let interview = Object.assign(this.getInterviewSchedule, {...this.interview})
          this.$store.commit('interview/SET_INTERVIEW_SCHEDULE_DETAILS', interview)
          this.$router.push({name: 'InterviewScheduleView', params: {id:this.$route.params.id,step: '3'},query: this.$route.query
          //       {
          //   jobId:job,
          //     email:this.$route.query.email,
          //     id:this.$route.query.id,
          //     name:this.$route.query.name
          // }
          })
        }
      }
  }
}
</script>

<style scoped lang="scss">
.type{
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #001343;
}
.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details {
  padding: 15px !important;
}


</style>

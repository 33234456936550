<template>
<div>
  <div class="tw-mt-16 tw-mb-10 lg:tw-w-7/12	">
    <h2 class="type">What type of Interview would you like to schedule?</h2>
  </div>
  <div>
    <div  class="option-div lg:tw-w-9/12 onHover" id="physical" @click="setInterviewType('physical')">
      <MapPinIcon class="icon-class"/>
      <h4>Physical Session</h4>
      <v-spacer/>
      <ArrowRightIcon  class="icon-class"/>
    </div>
    <div class="option-div tw-mt-5 lg:tw-w-9/12 tw-mb-5 onHover" id="virtual" @click="setInterviewType('virtual')">
      <MonitorIcon  class="icon-class"/>
      <h4>Virtual Session</h4>
      <v-spacer/>
      <ArrowRightIcon  class="icon-class"/>
    </div>
  </div>
</div>
</template>

<script>
import {MapPinIcon} from 'vue-feather-icons'
import {ArrowRightIcon} from "vue-feather-icons";
import {MonitorIcon} from 'vue-feather-icons'

export default {
name: "InterviewScheduleType",
  components:{MapPinIcon,ArrowRightIcon,MonitorIcon},

  methods:{
      setInterviewType(type){
        let job = +this.$route.query.jobId || +this.$route.query.program_id
        let data
        if(this.$route.query.program_id){
          data ={
            type:type,
            user_invited:[this.$route.query.email],
            interview_app: "program",
            program_applicant: + this.$route.query.applicant_id
          }
        }
        else{
          data ={
            type:type,
            job:job,
            user_invited:[this.$route.query.email],
            interview_app: "recruit",
          }
        }
        this.$store.commit('interview/SET_INTERVIEW_SCHEDULE_DETAILS',data
        )
        this.$router.push({name:'InterviewScheduleView',params:{id:this.$route.params.id, step:'2'},query:this.$route.query
          // jobId:this.$route.query.jobId,
          //   email:this.$route.query.email,
          //   id:this.$route.query.id,
          //   name:this.$route.query.name
          })
      }
  }
}
</script>

<style scoped lang="scss">
.type{
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #001343;
}
.option-div{
  background: #FFFFFF;
  border: 1px solid #9CABB5;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  cursor: pointer;
}
.option-div h4{
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  margin-left: 30px;
  /*margin-right: ;*/
  color: #001343;
}
.option-div:hover{
color: #008EEF;
  h4{
    color: #008EEF;
  }
}


</style>

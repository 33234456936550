<template>
<div class="wrapper">
  <Header @cancel="$router.push({name:'RecruiterDashboard'})"/>
  <div class="lg:tw-w-4/12 md:tw-w-4/12 tw-px-4 tw-mt-36 lg:tw-flex tw-justify-center tw-items-center">
      <span class="step ">STEP {{$route.params.step}} OF 5</span>
  </div>
  <div class="tw-px-4 lg:tw-w-11/12 md:tw-w-11/12	applyFlexCenter">
  <keep-alive>
    <component v-bind:is="displayInterviewScheduleComponent()"></component>
  </keep-alive>
  </div>
</div>
</template>

<script>
import Header from "@/components/onboarding/reuseables/Header";
import InterviewScheduleType from "@/components/interview/InterviewScheduleType";
import InterviewScheduleDateAndTime from "@/components/interview/InterviewScheduleDateAndTime";
import InterviewScheduleCreateLinkOrVenue from '@/components/interview/InterviewScheduleCreateLinkOrVenue'
import InterviewScheduleDetails from '@/components/interview/InterviewScheduleDetails'
import InviteAdminForInterview from "@/components/interview/InviteAdminForInterview";
export default {
name: "InterviewScheduleView",
  components: {Header,InterviewScheduleType,InterviewScheduleDateAndTime,InterviewScheduleCreateLinkOrVenue,InterviewScheduleDetails,InviteAdminForInterview},
  methods:{
      displayInterviewScheduleComponent(){
        let param = this.$route.params.step
        if(param === '1'){
          return InterviewScheduleType

        }
        else if(param === '2'){
          return  InterviewScheduleDateAndTime
        }
        else if(param === '3'){
          return  InterviewScheduleCreateLinkOrVenue
        }
        else if(param === '4') {
          return InterviewScheduleDetails
        }
        else {
          return InviteAdminForInterview
        }
      }
  }
}
</script>

<style scoped>
.step{
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #1E323F;
}
.wrapper{
  overflow: hidden !important;
}


</style>

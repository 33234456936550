<template>
  <div>
    <div class="tw-mt-14 tw-mb-8" v-if="getInterviewSchedule&& getInterviewSchedule.type==='virtual'">
        <h2 class="type tw-mb-2">Create Meeting Link</h2>
      <div class="tw-mt-5">
        <small>Virtual link</small>
        <v-text-field
            v-model="virtual_link"
            class=""
            id="link"
            solo
            label="Please enter meeting link"
        />
      </div>
      <div>
        <small>Additional Details</small>
        <v-textarea v-model="additional_details" id="detail" auto-grow rows="3" solo class="tw-w-350" label="Please enter additional details "/>
      </div>
    </div>

    <div v-else>
      <div class="tw-mt-16 tw-mb-10 lg:tw-w-7/12	">
        <h2 class="type">Where would this Interview be held?</h2>
      </div>
      <div class=" lg:tw-w-9/12">
        <small>Venue</small>
        <v-autocomplete
            v-model="venue"
            :items="searchResults"
            :search-input.sync="location"
            solo
            hide-details
            class="tw-pt-16"
            id="venue"
            :loading="isLoading"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                Search for your venue
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-autocomplete>
<!--        <v-text-field-->
<!--            v-model="venue"-->
<!--            class="tw-pt-16"-->
<!--            solo-->
<!--        />-->
      </div>
    </div>
    <div class="tw-flex tw-mt-8 tw-mb-8">
      <BaseButton button-text="Back" outlined width="85px" @click="$router.go(-1)"/>
      <BaseButton button-text="Next" class="tw-ml-4" depressed width="85px" @click="setInterviewSchedule"/>
    </div>

  </div>

</template>

<script>
import BaseButton from "@/components/UIComponents/BaseButton";
import {mapGetters} from 'vuex'

export default {
  name: "InterviewScheduleCreateLinkOrVenue",
  components: {BaseButton},
  data() {
    return {
        venue: '',
        virtual_link:'',
      additional_details:'',
      searchResults: [],
      service: null,
      location:null,
      isLoading:false

    }
  },
  metaInfo() {
    return {
      script: [{
        src: `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_API}&libraries=places`,
        async: true,
        defer: true,
        callback: () => this.MapsInit() // will declare it in methods
      }]
    }
  },

  computed: {
    ...mapGetters("interview", ['getInterviewSchedule'])
  },
  watch: {
    location(newValue) {
      if (newValue) {
        this.service.getPlacePredictions({
          input: this.location,
          types: ['address']
        }, this.displaySuggestions)
      }
    }
  },
  methods: {
    validateField(){
      if(this.getInterviewSchedule.type === 'physical' && !this.venue){
        this.$displaySnackbar({
          message: 'Please provide interview venue',
          success: false
        })
        return false
      }
      else if(this.getInterviewSchedule.type === 'virtual' && !this.virtual_link){
        this.$displaySnackbar({
          message: 'Please provide interview link',
          success: false
        })
        return false
      }
      else {
        return true
      }

    },
    setInterviewSchedule() {
      if(this.validateField()) {
        if (this.getInterviewSchedule.type === 'physical') {
          let interview = Object.assign(this.getInterviewSchedule, {venue: this.venue})
          this.$store.commit('interview/SET_INTERVIEW_SCHEDULE_DETAILS', interview)
        } else {
          let interview = Object.assign(this.getInterviewSchedule, {virtual_link: this.virtual_link, additional_details: this.additional_details})
          this.$store.commit('interview/SET_INTERVIEW_SCHEDULE_DETAILS', interview)
        }

        this.$router.push({name: 'InterviewScheduleView', params: {id:this.$route.params.id,step: '4'},query:this.$route.query
        //       {
        //   jobId:this.$route.query.jobId,
        //     email:this.$route.query.email,
        //     id:this.$route.query.id,
        //     name:this.$route.query.name
        // }
        })
      }
    },
    MapsInit() {
      this.service = new window.google.maps.places.AutocompleteService()
    },
    displaySuggestions(predictions, status) {
      this.isLoading = true
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        this.searchResults = []
        return
      }
      this.searchResults = predictions.map(prediction => prediction.description)
      this.isLoading = false
    },
  }
}
</script>

<style lang="scss" scoped>
.type {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #001343;
}

> > > .v-input__slot::before {
  padding: 15px !important;
}

> > > .v-input__slot::after {
  padding: 15px !important;

}


</style>

<template>
  <div>
    <div class="tw-mt-14 tw-mb-12">
      <div class="almost">
        <h2>ALMOST DONE!</h2>
        <h5>Confirm Details</h5>
      </div>
      <div>
        <h3 class="candidate tw-my-4 lg:tw-w-7/12">You’re about to invite {{$route.query.name}} for an
          Interview.</h3>
        <v-divider/>
      </div>
      <div class="details">
        <v-row>
          <v-col class="tw-mt-8 tw-mb-4 ">
            <span>TIME</span>
            <h1>{{formatTime}}</h1>
          </v-col>
          <v-col class="tw-mt-8 tw-mb-4">
            <span>DATE</span>
            <h1>{{computedDateFormattedMomentJs}}</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span>MODE</span>
            <h1 class="tw-capitalize">{{getInterviewSchedule.type}}</h1>
          </v-col>
          <v-col>
            <span>VENUE</span>
            <h1>{{getInterviewSchedule.venue || getInterviewSchedule.virtual_link}}</h1>
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="tw-flex tw-mt-10 tw-mb-10">
      <BaseButton outlined button-text="Back" width="85px" @click="$router.go(-1)"/>
      <BaseButton :loading="loading" depressed button-text="Invite for Interview" @click="sendInvite" class="tw-ml-4" width="192px" />
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/UIComponents/BaseButton";
import {mapGetters} from "vuex";
import moment from "moment";
import {sendInterviewInvite} from "@/services/api/APIService";
import {createInterview} from "@/services/api/APIService";
export default {
  name: "InterviewScheduleDetails",
  components: {BaseButton},
  data(){
    return{
      loading:false,

    }
  },
  computed:{
    ...mapGetters("interview",['getInterviewSchedule']),
    computedDateFormattedMomentJs () {
      return this.getInterviewSchedule.date ? moment(this.getInterviewSchedule.date).format('dddd, MMMM Do YYYY') : ''
    },
    formatTime(){
      return this.getInterviewSchedule.time ? moment(this.getInterviewSchedule.time, ["HH.mm"]).format("hh:mm a") : ''  // 1:55 PM
    }
  },
  methods:{
      sendInvite(){
        this.loading = true
        if(this.$route.query.from ==='admin-workspace-programs-programdetails'){
          let data={
            program: +this.$route.query.program_id,
            site_name: this.$route.query.site_name,
            applicant: +this.$route.query.applicant_id,
            type: this.getInterviewSchedule.type,
            date: this.getInterviewSchedule.date,
            time: this.getInterviewSchedule.time,
            venue: this.getInterviewSchedule.venue,
            virtual_link: this.getInterviewSchedule.virtual_link
          }
          console.log(data)
          createInterview(data)
              .then(res=> {
                this.$displaySnackbar({
                  message: res.data.detail,
                  success: true
                })
                console.log(res.data.interview_id)
                if(this.$route.query.program_id){
                  let queryDetails = this.$route.query
                  console.log(queryDetails)
                  this.$router.push({
                    name: 'CandidateProfile',
                    params: {id:this.$route.params.id},
                    query: queryDetails
                  })
                }
              })
              .catch(err=>{
                this.$displaySnackbar({
                  message: err.response.data.detail,
                  success: false
                })
              })
              .finally(()=>this.loading=false)
        }
        else{
          sendInterviewInvite(this.getInterviewSchedule)
              .then(res=> {
                this.$displaySnackbar({
                  message: res.data.detail,
                  success: true
                })
                this.$router.push({
                  name: 'InterviewScheduleView', params: {step: '5'}, query: {
                    jobId: this.$route.query.jobId,
                    email: this.$route.query.email,
                    id: this.$route.query.id,
                    name: this.$route.query.name,
                    // interviewId:29
                    interviewId: res.data.interview_id
                  }
                })
              })
              .catch(err=>{
                this.$displaySnackbar({
                  message: err.response.data.detail,
                  success: false
                })
              })
              .finally(()=>this.loading=false)
        }
      }
  }
}
</script>

<style scoped>
.almost h2 {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #1E323F;
}

.almost h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #001343;
  margin-top: 7px;
  margin-bottom: 25px;
}

.candidate {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #1E323F;
}

span {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #1E323F;
}

.details h1 {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #1E323F;
  margin-top: 7px;
}
</style>
